import { ChainId } from '@abstra-dex/sdk'
import { CHAIN_ID } from 'config/constants/networks'

const chainId = parseInt(CHAIN_ID, 10) as ChainId

const getTokenLogoURL = (address: string) => {
  return `https://github.com/TunaWho/token-asset/blob/master/blockchains/zkf/assets/${address.toLowerCase()}/logo.png?raw=true`
}

export default getTokenLogoURL
